import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import Products from './pages/Products';
import Receipts from './pages/Receipts';
// import Contact from './pages/Contact';

function AppRoutes() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/sobre-nos" element={<AboutUs />} />
        <Route path="/produtos" element={<Products />} />
        {/* <Route path="/contato" element={<Contact />} /> */}
        <Route path='/pedido' element={<Receipts/>} />
      </Routes>
    </Router>
  );
}

export default AppRoutes;
